.tracking-app-host {
    align-items: center;
    background-color: $white-color;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    padding: 1.6rem;

    .tracking-app-content {
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 3.2rem;

        .tracking-app-logo {
            max-width: 18rem;
            width: 100%;

            img {
                width: 100%;
            }
        }

        .MuiCard-root {
            box-shadow: rgba(0, 0, 0, 0.08) 0 0.6rem 3rem;
            overflow: hidden;

            &.small {
                max-width: 36rem;
                width: 100%;

                .MuiCardHeader-title {
                    text-align: center;
                }

                .card-content {
                    align-items: center;
                    display: flex;
                    flex-direction: column;
                    gap: 1.6rem;
                    padding-bottom: 3.2rem;
                }

                .MuiButton-root {
                    width: 100%;

                    span:nth-child(n+1) {
                        padding-right: 0.5rem;
                    }
                }
            }

            &.tracking-view-card {
                min-height: 50vh;
                width: 90rem;

                .job-info-container {
                    .job-info {
                        gap: 1.6rem;
                        padding: 2.4rem 1.6rem;

                        .location-points {
                            padding: 0 1.6rem;
                        }

                        .job-view-header {
                            padding: 0;
                        }

                        .job-details-list {
                            margin: 0;
                        }

                        .job-information {
                            padding: 0 0.8rem;
                        }

                        .job-timer {
                            align-items: center;
                            border-top: 1px solid $icon-bg;
                            display: flex;
                            padding: 1rem 0;

                            .bold-text {
                                font-weight: 700;
                            }
                        }

                        .job-view-circular-progress-wrapper {
                            align-items: center;
                            background-color: transparent;
                            bottom: -2.3rem;
                            display: flex;
                            justify-content: center;
                            position: absolute;
                            width: 100%;

                            .job-view-circular-progress-divider {
                                left: 0;
                                position: absolute;
                                right: 0;
                            }

                            .progress-string-value-wrapper {
                                align-items: center;

                                .progress-string-value {
                                    font-size: $base-font-size;
                                    font-weight: 700;
                                    line-height: 1rem;

                                    &.hour-input {
                                        font-size: $small-font-size;
                                    }
                                }

                                .progress-string-measurement-unit {
                                    font-size: $xxs-font-size;
                                    font-weight: 500;
                                    line-height: 1rem;
                                }
                            }

                            .job-view-circular-progress-progress-item {
                                border-radius: 50%;
                            }
                        }

                        .additional-info {
                            padding: 0.4rem 0.8rem;
                        }

                        .description-text {
                            min-height: 10rem;
                            padding: 0.4rem 0.8rem;
                        }
                    }
                }

                .map-container {
                    .leaflet-container {
                        height: 100%;
                        margin-top: 0;
                        width: 100%;
                    }
                }
            }

            .MuiCardHeader-root {
                padding-top: 1.6rem;
            }
        }
    }
}
@media (max-width: $breakpoint-medium) {
    .tracking-app-host {
        justify-content: flex-start;
        padding: 1.6rem 0;

        .tracking-app-content {
            gap: 1.6rem;
            margin-bottom: 0;
            width: 100%;

            .tracking-app-logo {
                max-width: 10rem;
            }

            .MuiCard-root {
                border-radius: 0;
                box-shadow: none;
                max-height: unset;

                &.tracking-view-card {
                    width: 100%;

                    .job-info-container {
                        order: 2;

                        .job-info {
                            flex: 1;
                            padding: 1.6rem 0;

                            .job-information {
                                padding: 0 1.6rem;
                            }

                            .additional-info {
                                padding: 0.4rem 1.6rem;
                            }

                            .description-text {
                                padding: 0.4rem 1.6rem;
                            }
                        }
                    }

                    .map-container {
                        display: flex;
                        height: 20rem;
                        order: 1;
                        overflow: hidden;
                    }
                }

                .mobile-hide {
                    display: none;
                }
            }
        }
    }
}